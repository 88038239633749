
 // @ is an alias to /src
import Navigation from '@/components/partials/Navigation.vue';
import Aside from '@/components/partials/Aside.vue';
import QuickCard from '@/components/partials/QuickCard.vue';
import Footer from '@/components/partials/Footer.vue';
import { defineComponent, ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: "Lesson",
  components: {
    Navigation,
    Aside,
    QuickCard,
    Footer,
  },
  setup() {
    const store = useStore();
    const clicks = reactive([
      {
        name: "Add Lesson",
        route: "CreateLesson"
      },
      {
        name: "Update Lesson",
        route: "EditLesson"
      },
    ]);
    const links = reactive([
      {
        name: "Overview",
        url: "account"
      },
      {
        name: "Quick Links",
        url: "account"
      },
    ]);
    const name = ref("Lesson");
    const nav = computed(() => store.getters.nav);

    return { name, nav, links, clicks };
  },
});
